import React, { useEffect, useState } from "react";

import PortfolioCom from "../components/Home/PortfolioCom";
import Layout from "../components/Layout";
import Hero from "../components/Mobileapp/hero";
import Seo from "../components/Seo";
import allApiData from '../api';
import SeoheadData from "../siteScriptData";


export default function Newportfolio({ location }) {

  const tabNameData = ['App Development', 'Game Development', 'Website Development', 'Art & Animation'];
  const [allData, setallData] = useState({});
  const [portfoliosTotal, setPortfoliosTotal] = useState()
  useEffect(() => {
    allApiData.aosinit()
    if (portfoliosTotal !== allData.total) {
      allApiData.allPortfolios().then((response) => {
        setPortfoliosTotal(response.total)
        setallData(response)
      });
    }
  }, []);

  return (
    <Layout>
      <Seo SeoData={SeoheadData.portfoliosPageSeoData}></Seo>
      <main className="pb-5">
        <div className="newportfolio">
          <Hero
            title="Our Wall of Pride and Creativity"
            def="When we say that Vasundhara Infotech is a leading custom software development solution provider in the India, there’s a reason behind it - Our work. Check out our portfolio where you will come across some of the mind-blowing projects we have been a part of. "
            cta='Get a Quote'
          >
            <div className="hero-img hide-animation-768">
              <img
                src="../assets/img/portfolio/hero_img/vasundhara-portfolio.svg"
                className="wh-auto z-index img-fluid"
                alt="vasundhara-portfolio"
                width="743" height="521"
              />
              <img
                src="../assets/img/portfolio/hero_img/1.svg"
                className=" img-fluid  img-one"
                alt="vasundhara-portfolio-one"
              />
              <img
                src="../assets/img/portfolio/hero_img/1.svg"
                className=" img-fluid  img-two"
                alt="vasundhara-portfolio-two"
              />
              <img
                src="../assets/img/portfolio/hero_img/1.svg"
                className=" img-fluid  img-three"
                alt="vasundhara-portfolio-three"
              />
              <img
                src="../assets/img/portfolio/hero_img/1.svg"
                className=" img-fluid  img-four"
                alt="vasundhara-portfolio-four"
              />
              <img
                src="../assets/img/portfolio/hero_img/1.svg"
                className=" img-fluid  img-five"
                alt="vasundhara-portfolio-five"
              />
            </div>
            <div className="show-main-img-768">
              <img
                src="../assets/img/portfolio/hero_img/vasundhara-portfolio-mobile.svg"
                className="wh-auto img-fluid"
                alt="vasundhara-portfolio-six"
                width="680" height="477"
              />
            </div>
          </Hero>
        </div>
        <div className="main-portfolio">
          {/* <PortfolioCom
            heading="Portfolio"
            isTabOn={true}
            isImageComp={true}
            isViewBtn={false}
            currentpagename={location.state ? location.state.industryTitle : 'All'}
            viewurl={"newportfolio"}
            isPagination={true}
            tabData={portfolioData}
            portfoliochange={true}
          /> */}
          <PortfolioCom
            heading="Our Wall of Pride"
            isTabOn={true}
            isImageComp={true}
            isViewBtn={false}
            viewurl={'portfolio'}
            viewButton={'View the entire portfolio'}
            tabData={tabNameData}
            typeWise='app_type'
            isPagination={true}
            portfolios={allData.portfolios}
          />
        </div>
      </main>
    </Layout>
  );
}
